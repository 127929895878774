import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import ProductHeading from '../Pages/ProductPage/ProductHeadingSection/ProductHeading';
import AquanurchImpact from '../Pages/ProductPage/ProductImapctSection/AquanurchImpact';
import AquanurchSystem from '../Pages/ProductPage/AquanurchSystemSection/AquanurchSystem';
import ProductsTab from '../Pages/ProductPage/ProductsTab/ProductsTab';
import BuildingBlocks from '../Pages/ProductPage/BuildingBlocksSection/BuildingBlocks';
import GetInTouch from '../Pages/ProductPage/GetInTouchSection/GetInTouch';
import CompanySection from '../components/CompanySectrion/CompanySection';
import FooterSection from '../components/FooterSection/FooterSection';
import MobileDropdown from '../components/Dropdown/MobileDropdown';
import NewsSection from '../components/NewsSection/NewsSection';
import PricingSection from '../Pages/ProductPage/PricingSection/PricingSection';

function Product({ isOpen, toggleDropdown }) {
	return (
		<>
			{!isOpen ? (
				<div className='flex flex-col w-full overflow-x-hidden'>
					<Navbar isOpen={isOpen} toggleDropdown={toggleDropdown} />
					<ProductHeading />
					<AquanurchSystem />
					<BuildingBlocks />
					<ProductsTab />
					<AquanurchImpact />
					<PricingSection />
					<NewsSection />
					<GetInTouch />
					<CompanySection />
					<FooterSection />
				</div>
			) : (
				<MobileDropdown
					toggleDropdown={toggleDropdown}
					className='flex flex-col h-full w-full overflow-x-hidden'
				/>
			)}
		</>
	);
}

export default Product;
