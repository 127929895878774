import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './AllPages/Home';
import Product from './AllPages/Product';
import Career from './AllPages/Career';
import PrivacyPolicy from './AllPages/PrivacyPolicy';
import UsePolicy from './AllPages/UsePolicy';
import RefundPloicy from './Pages/RefundPolicy/RefundPolicy';
import PolicyHeaderApp from './Pages/PrivacyPolicyPageApp/PolicyHeaderApp/PolicyHeaderApp';

const TRACKING_ID = 'UA-170594295-2';
ReactGA.initialize(TRACKING_ID);

function App() {
	// const location = useLocation();
	// console.log(location.pathname);
	const [isOpen, setIsOpen] = useState(false);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return (
		<Router>
			<Routes>
				<Route
					path='/careers'
					element={<Career isOpen={isOpen} toggleDropdown={toggleDropdown} />}
				/>

				<Route
					exact
					path='/product'
					element={<Product isOpen={isOpen} toggleDropdown={toggleDropdown} />}
				/>

				<Route
					exact
					path='/'
					element={<Home isOpen={isOpen} toggleDropdown={toggleDropdown} />}
				/>

				<Route
					exact
					path='/privacypolicy'
					element={
						<PrivacyPolicy isOpen={isOpen} toggleDropdown={toggleDropdown} />
					}
				/>

				<Route
					exact
					path='/privacypolicy-app'
					element={
						<PolicyHeaderApp isOpen={isOpen} toggleDropdown={toggleDropdown} />
					}
				/>

				<Route
					exact
					path='/usepolicy'
					element={
						<UsePolicy isOpen={isOpen} toggleDropdown={toggleDropdown} />
					}
				/>

				<Route
					exact
					path='/refundpolicy'
					element={
						<RefundPloicy isOpen={isOpen} toggleDropdown={toggleDropdown} />
					}
				/>
			</Routes>
		</Router>
	);
}

export default App;
